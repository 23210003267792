import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const HurleyGigiItsy = () => (
  <Layout>
    <SEO title="Hurley Gigi Itsy" />
    <h1>Hi we're Hurley, Gigi &amp; Itsy</h1>
    <p>Two brothers &amp; a sister. All 3 adopted together on January 31st, 2021</p>
    <div>
      <img src="https://res.cloudinary.com/angular/image/upload/c_scale,w_600/v1615769613/welovetonky/hurley-gigi-itsy.jpg" alt="Hurley Gigi Itsy" />
    </div>
    <Link to="/">Go back to the homepage</Link>
  </Layout>
)

export default HurleyGigiItsy
